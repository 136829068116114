import React, {useEffect, useState } from "react"
import { AutoSizer } from "react-virtualized";
import {DiscreteColorLegend, HorizontalGridLines, VerticalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis, Hint } from "react-vis";
import { COLOURS } from "../techsales/TechSales";
import { formatLocale } from "d3-format";

interface Props {
    chartData: any[]
    percentageMode: boolean
}






const RevenueSegmentationStackedPercentageChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();
    const [key, setKey] = useState<string>();
    const [chartDataStore, setChartDataStore] = useState<any[]>([]);



    enum CURRENCY {
        US = "$",
        UK = "£",
        FR = "€",
        DE = "€"
    }

    enum CURRENCY_TYPE {
        US = "USD",
        UK = "GBP",
        FR = "EUR",
        DE = "EUR"
    }

    const customLocale = formatLocale({
        currency: [CURRENCY.US, ""],
        decimal: ".",
        thousands: ",",
        grouping: [3]
    });


    const getColour = (key) => {
        const index = chartDataStore.findIndex((chart) => {
            return chart[key] !== undefined});
        if(index < COLOURS.length){
            return COLOURS[index]
        }
        return COLOURS[-index]
    }

    const calculateYearlyTotal = (product: any) => {
        let yearlyTotals = 0
        Object.keys(product).forEach( year => {
            yearlyTotals += Number(product[year])
        })
        return yearlyTotals;
    };

    const handleSortByYearDesc = (data) => {
        return Object.fromEntries(
            Object.entries((data).sort((a,b) => parseInt(a[0]) - parseInt(b[0])))
        );
    };

    function extractUniqueYears(): string[] {
        const uniqueYears: Set<string> = new Set();

        for (const productName in props.chartData) {
            if (props.chartData.hasOwnProperty(productName)) {
                const product = props.chartData[productName];
                for (const year in product) {
                    if (product.hasOwnProperty(year)) {
                        uniqueYears.add(year);
                    }
                }
            }
        }

        const sortedYears = Array.from(uniqueYears).sort((a, b) => parseInt(a) - parseInt(b));
        return sortedYears;
    }

    const getGraphData = (product) => {

        if(props.percentageMode){
            const data = Object.keys(props.chartData[product]).map((year) => ({x: year, y: getSumOfYear(Number(year), props.chartData[product][year])}));
            return data;

        }

        const data = Object.keys(props.chartData[product]).map((year) => ({x: year, y: props.chartData[product][year]}))
        return data;
    }

    const getSumOfYear = (year: number, price: number) => {
        let sum = 0
        
        Object.keys(props.chartData).forEach((product) => {

            if(props.chartData[product][year] === undefined){
                return;
            }
            sum = sum + Number(props.chartData[product][year])
        })

        let percentage = (price / sum) * 100
        return percentage
    }

    useEffect(() => {
        setChartDataStore(Object.keys(props.chartData).map(key => ({ [key]: props.chartData[key] })));
    }, []);


    return (
        <AutoSizer>
            {({ height, width }) => (
                <div>
                    <XYPlot
                        xType="ordinal"
                        stackBy="y"
                        width={width}
                        height={350}
                        xDomain={extractUniqueYears()}
                    >


                        {hover && (
                            <Hint value={hover}>
                                <div className="chart-hint">
                                    <span>{key}</span>
                                    {props.percentageMode ? (
                                        <>
                                            <div
                                                className="x">Total
                                                Percentage: {getSumOfYear(Number(hover.x), props.chartData[key][hover.x]).toFixed(0)}%
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className="x">Revenue: {customLocale.format('$,.3s')(+props.chartData[key][hover.x]).replace(/G/, "B")}</div>
                                            <div
                                                className="x">Total Percentage: {getSumOfYear(Number(hover.x), props.chartData[key][hover.x]).toFixed(0)}%
                                            </div>
                                            <div className="y">{hover.x}</div>
                                        </>
                                    )}
                                </div>
                            </Hint>
                        )}

                        <VerticalGridLines/>
                        <HorizontalGridLines/>
                        <XAxis tickFormat={(tick) => tick.toString()}
                               tickTotal={4}
                        />
                        <YAxis tickFormat={() => ''}/>
                        {Object.keys(props.chartData).sort((a,b) => calculateYearlyTotal(props.chartData[a]) - calculateYearlyTotal(props.chartData[b])).map((product) => (
                            <VerticalBarSeries
                                barWidth={0.5}
                                color={getColour(product)}
                                data={getGraphData(product)}

                                onValueMouseOver={(d) => {
                                    setHover(d)
                                    setKey(product)
                                }}
                                onValueMouseOut={(d) => {
                                    setHover(null);
                                    setKey(null);
                                }}
                            />
                        ))}
                        <DiscreteColorLegend orientation="horizontal" width={600}

                                             items={Object.keys(props.chartData)?.sort((a,b) => calculateYearlyTotal(props.chartData[b]) - calculateYearlyTotal(props.chartData[a])).map((product, index) => {
                                                 return {
                                                     title: product,
                                                     color: getColour(product),
                                                 }
                                             })}/>
                    </XYPlot>
                </div>
            )}
        </AutoSizer>
    )

}


export default RevenueSegmentationStackedPercentageChart