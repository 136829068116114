import axios from 'axios';
import React, {Dispatch, useContext, useState} from 'react';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import {Button, Col, Container, Row} from 'react-bootstrap';
import OrganisationEditorDetails from './editor/OrganisationEditorDetails';
import OrganisationEditorSocials from './editor/OrganisationEditorSocials';
import DomainAliasEditor from './editor/DomainAliasEditor';
import TickerEditor from './editor/TickerEditor';
import DigitalMaturityScoreBreakdown from './editor/DigitalMaturityScoreBreakdown';
import {caseStudiesHostName, organisationHostname, socialHostName} from '../../utils/Configuration';
import {SocialScraperResponse} from '../../model/organisation/social/SocialScraperResponse';
import {faFacebook, faLinkedin, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {StateHandler} from '../../model/organisation/editor/StateHandler';
import EditorButton from './EditorButton';
import {ErrorHandler} from '../../model/organisation/editor/ErrorHandler';
import {CaseStudiesResponse} from '../../model/organisation/editor/CaseStudiesResponse';
import CaseStudiesWhitePaperFunctions from './CaseStudiesWhitePaperFunctions';
import NewDisplayFetchCompanyDetailsModal from './NewDisplayFetchCompanyDetailsModal';

import './OrganisationEditor.css';
import './OrganisationSummary.css';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import {showErrorWithMessage} from "../common/CommonAlerts";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const NewOrganisationEditor: React.FC = () => {

    // Instead of redefining it every useState
    const intialStateHandler = {loading: false, success:false, error: {state: false, message: ""}}
    const {organisation, setOrganisation, fetchCompany} = useContext(UserOrganisationContext);
    const [displayModal, setDisplayModal] = useState<boolean>(false)


    //Global Success and Error For Sweet Alerts
    const [error, setError] = useState<ErrorHandler>({state: false, message: ""})
    const [loading, setLoading] = useState<boolean>(false)

    const [runCaseStudiesWhitePaper, setRunCaseStudiesWhitePaper] = useState<boolean>(false)

    // Response Data
    const [socialScraperResponse, setSocialScraperResponse] = useState<SocialScraperResponse | null>(null);
    const [caseStudiesResponse, setCaseStudiesResponse] = useState<CaseStudiesResponse | null>(null);
    const [whitePaperResponse, setWhitePaperResponse] = useState<CaseStudiesResponse | null>(null);

    //State Handling
    const [scrapeSocialHandlesData, setScrapeSocialHandlesData]= useState<StateHandler>(intialStateHandler);
    const [updateMarketData, setUpdateMarketData] = useState<StateHandler>(intialStateHandler);
    const [scrapeDirectorImagesData, setScrapeDirectorImagesData] = useState<StateHandler>(intialStateHandler);
    const [updateCompanyProfileData, setUpdateCompanyProfileData] = useState<StateHandler>(intialStateHandler);
    const [updateFilingHistoryData, setUpdateFilingHistoryData] = useState<StateHandler>(intialStateHandler);
    const [updateOfficersAndAppointmentsData, setUpdateOfficersAndAppointmentsData] = useState<StateHandler>(intialStateHandler);
    const [updateFinancialStatementsData, setUpdateFinancialStatementsData] = useState<StateHandler>(intialStateHandler);
    const [updateGatheringCaseStudiesData, setUpdateGatheringCaseStudiesData] = useState<StateHandler>(intialStateHandler)
    const [updateProcessingCaseStudiesData, setUpdateProcessingCaseStudiesData] = useState<StateHandler>(intialStateHandler)
    const [updateGatheringWhitePaperData, setUpdateGatheringWhitePaperData] = useState<StateHandler>(intialStateHandler)
    const [updateProcessingWhitePaperData, setUpdateProcessingWhitePaperData] = useState<StateHandler>(intialStateHandler)

    const [processInvestorRelationsLoading, setProcessInvestorRelationsLoading] = useState<boolean>(false);
    const [processInvestorRelationsSuccess, setProcessInvestorRelationsSuccess] = useState<boolean>(false);
    const [processInvestorRelationsError, setProcessInvestorRelationsError] = useState<boolean>(false);

    const [gatherInvestorRelationsData, setgatherInvestorRelationsData] = useState<StateHandler>({loading: false, success:false, error: {state: false, message: ""}});


    const processInvestorRelations = async () => {
        setProcessInvestorRelationsLoading(true);
        await axios.get(caseStudiesHostName + "investors/process/" + organisation.id)
            .then(() => {
            })
            .finally(() => setProcessInvestorRelationsLoading(false));
    }

    const updateHandler = (e: any, updateButton: boolean, url: string, setStateData: Dispatch<StateHandler>, stateData: StateHandler ) => {
        e.preventDefault();

        if(!updateButton){
            setLoading(true);
        }

        setStateData({...stateData, loading: true})

        if(url.includes("investors/gather")) {

            let request = {
                id: organisation.id,
                url: organisation.companyUrl
            };

            axios.post(url, request)
                .then((r) => {
                    setStateData({...stateData, loading: false})
                    processInvestorRelations();
                }).finally(() => {
                setLoading(false);
                setStateData({...stateData, loading: false})
                return;
            });
        } else if (url.includes('market-update')) {

            const queryParams = {
                manualUpdate: true,
            };

            axios.get(url, { params: queryParams })
                .then((r) => {
                    setStateData({...stateData, loading: false})
                }).finally(() => {
                setLoading(false);
                setStateData({...stateData, loading: false})
                return;
            });
        } else {
            axios.get(url)
                .then((response) => {

                    if (url.includes('social-scrape')) {

                        let data = response.data

                        if (!data.linkedIn && !data.youtube && !data.facebook && !data.twitter) {
                            setStateData({...stateData, error: {state: true, message: "No Social Media Infomation"}})
                        } else {
                            setSocialScraperResponse(data);
                        }
                    }

                    fetchCompany()
                    setStateData({...stateData, loading: false, success: true})

                    if (!updateButton) {
                        showSuccessDialog();
                    }
                })
                .catch((reason) => {
                    setStateData({...stateData, loading: false, error: {state: true, message: reason.response.data.title}})
                    if (!updateButton) {
                        setError({state: true, message: reason.response.data.title })
                        showErrorWithMessage("An error has occurred!", reason.response.data.title)
                            .then((result) => setSocialScraperResponse(null));
                    }
                })
                .finally(() => setLoading(false));
        }

    }

    const SuccessInfo = () => {
        return (
            <>
                Save successful!
                {!!socialScraperResponse && (
                    <div className="mt-3">
                        <div className="organisation-card-label">Social handles found</div>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faLinkedin}/>
                            </Col>
                            <Col>
                                {socialScraperResponse.linkedIn || "-"}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faTwitter}/>
                            </Col>
                            <Col>
                                {socialScraperResponse.twitter || "-"}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faFacebook}/>
                            </Col>
                            <Col>
                                {socialScraperResponse.facebook || "-"}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faYoutube}/>
                            </Col>
                            <Col>
                                {socialScraperResponse.youtube || "-"}
                            </Col>
                        </Row>
                    </div>
                )}
            </>
        );
    }
    const showSuccessDialog = () => {
        const SuccessDialog = withReactContent(Swal);
        SuccessDialog.fire({
            icon: "success",
            title: "Success!",
            html: <SuccessInfo />
        }).then(r => setSocialScraperResponse(null))

    }

    return (
        <div>
            <NewDisplayFetchCompanyDetailsModal
                displayModal={displayModal} handleClose={() => setDisplayModal(false)}
                socialScraperData={scrapeSocialHandlesData} updateProfileData={updateCompanyProfileData}
                updateFilingHistoryData={updateFilingHistoryData}
                updateOfficerAppointmentData={updateOfficersAndAppointmentsData}
                updateFinancialStatementData={updateFinancialStatementsData}
                directorScraperData={scrapeDirectorImagesData}
                caseStudiesGatherData={updateGatheringCaseStudiesData}
                caseStudiesProcessData={updateProcessingCaseStudiesData}
                whitePaperGatherData={updateGatheringWhitePaperData}
                whitepaperProcessData={updateProcessingWhitePaperData}
            />
            {loading ? <IqSmallLoadingIcon/> :
                <div style={{display: "flex", justifyContent: 'flex-start'}}>

                    <Button style={{marginTop: 15, marginRight: "15px"}}
                            variant="warning"
                            type="submit"
                            onClick={(e: any) => {
                                setDisplayModal(true);
                                updateHandler(e, true, socialHostName + 'organisation/' + organisation.id + '/social-scrape',
                                    setScrapeSocialHandlesData, scrapeSocialHandlesData);
                                updateHandler(e, true, organisationHostname + 'officer/scrape-profile-images/' + organisation.id,
                                    setScrapeDirectorImagesData, scrapeDirectorImagesData);
                                updateHandler(e, true, organisationHostname + 'organisation/update-profile?organisationIds=' + organisation.id,
                                    setUpdateCompanyProfileData, updateCompanyProfileData);
                                updateHandler(e, true, organisationHostname + 'organisation/update-filing-history?organisationIds=' + organisation.id,
                                    setUpdateFilingHistoryData, updateFilingHistoryData);
                                updateHandler(e, true, organisationHostname + 'organisation/update-officers-appointments?organisationIds=' + organisation.id,
                                    setUpdateOfficersAndAppointmentsData, updateOfficersAndAppointmentsData);
                                updateHandler(e, true, organisationHostname + 'organisation/' + organisation.id + '/update-financial-statements',
                                    setUpdateFinancialStatementsData, updateFinancialStatementsData);
                                setRunCaseStudiesWhitePaper(true)
                            }}>
                        Update Company
                    </Button>
                    <Button style={{ marginTop: 15 }}
                            variant="warning"
                            type="submit"
                            onClick={(e: any) => {
                                updateHandler(e, false, socialHostName + 'organisation/' + organisation.id + '/social-scrape',
                                    setScrapeSocialHandlesData, scrapeSocialHandlesData);
                                updateHandler(e, true, organisationHostname + 'officer/scrape-profile-images/' + organisation.id,
                                    setScrapeDirectorImagesData, scrapeDirectorImagesData);
                                updateHandler(e, true, caseStudiesHostName + "investors/gather",
                                    setgatherInvestorRelationsData, gatherInvestorRelationsData);
                            }}>
                        Auto Gather
                    </Button>

                    <EditorButton updateHandler={updateHandler} setStateData={setUpdateMarketData} stateData={updateMarketData} buttonText={"Fetch Market Data"}
                                  url={organisationHostname + 'organisation/' + organisation.id + '/market-update'}/>

                    <EditorButton updateHandler={updateHandler} setStateData={setUpdateFilingHistoryData} stateData={updateFilingHistoryData} buttonText={"Fetch Filing History"}
                                  url={organisationHostname + 'organisation/update-filing-history?organisationIds=' + organisation.id}/>

                    <EditorButton updateHandler={updateHandler} setStateData={setUpdateOfficersAndAppointmentsData} stateData={updateOfficersAndAppointmentsData} buttonText={"Fetch Officers/Appointments (async)"}
                                  url={organisationHostname + 'organisation/update-officers-appointments?organisationIds=' + organisation.id}/>

                    <EditorButton updateHandler={updateHandler} setStateData={setUpdateFinancialStatementsData} stateData={updateFinancialStatementsData} buttonText={"Fetch Financials (synced)"}
                                  url={organisationHostname + 'organisation/' + organisation.id + '/update-financial-statements'}/>

                    <EditorButton updateHandler={updateHandler} setStateData={setUpdateCompanyProfileData} stateData={updateCompanyProfileData} buttonText={"Fetch company profile"}
                                  url={organisationHostname + 'organisation/update-profile?organisationIds=' + organisation.id}/>

                    <CaseStudiesWhitePaperFunctions

                        setSuccess={showSuccessDialog} setError={setError} setLoading={setLoading}
                        run={runCaseStudiesWhitePaper} setRun={setRunCaseStudiesWhitePaper}

                        setGatheringCaseStudiesStateData={setUpdateGatheringCaseStudiesData} gatheringCaseStudiesStateData={updateGatheringCaseStudiesData}
                        caseStudiesResponse={caseStudiesResponse} setCaseStudiesResponse={setCaseStudiesResponse}

                        setProcessingCaseStudiesStateData={setUpdateProcessingCaseStudiesData} processingCaseStudiesStateData={updateProcessingCaseStudiesData}

                        setGatheringWhitePaperStateData={setUpdateGatheringWhitePaperData} gatheringWhitePaperStateData={updateGatheringWhitePaperData}
                        whitePaperResponse={whitePaperResponse} setWhitePaperResponse={setWhitePaperResponse}

                        setProcessingWhitePaperStateData={setUpdateProcessingWhitePaperData} processingWhitePaperStateData={updateProcessingWhitePaperData}
                    />

                </div>
            }
            <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 0, marginRight: 0, paddingTop: 15,}}>
                </div>
                <Row className="" style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                    <div className="col col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <OrganisationEditorDetails/>
                    </div>
                    <div className="col col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <OrganisationEditorSocials/>
                    </div>
                </Row>
                <Row style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                    <div className="col col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <DomainAliasEditor/>
                    </div>
                </Row>
                {<Row style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                    <div className="col col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <TickerEditor/>
                    </div>
                </Row>}
                <Row className="" style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                    <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <DigitalMaturityScoreBreakdown/>
                    </div>
                </Row>
            </Container>
        </div>


    )
}

export default NewOrganisationEditor;