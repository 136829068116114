import { useContext, useEffect, useState } from "react";
import UserOrganisationContext from "../../context/organisation/OrganisationContext";
import StockMarketSwitch from "./StockMarketSwitch";
import {Redirect, Route, matchPath, useLocation, useRouteMatch } from "react-router-dom";
import React from "react";
import OrganisationStockMarket from "./OrganisationStockMarket";
import { organisationHostname } from "../../utils/Configuration";
import axios from "axios";
import RevenueSegmentationLineSeriesChart from "./RevenueSegmentationLineSeriesChart";
import { Container } from "react-bootstrap";
import RevenueSegmentationStackedBarChart from "./RevenueSegmentationStackedBarChart";
import IqSmallLoadingIcon from "../common/IqSmallLoadingIcon";
import RevenueSegmentationStackedPercentageChart from "./RevenueSegmentationStackedPercentageChart";


const OrganisationStockProduct: React.FC = () => {

    const {
        organisation
    } = useContext(UserOrganisationContext);


    let match = useRouteMatch();
    let location = useLocation();
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();


    const [graphData, setGraphData] = useState<any[]>();
    const [chartData, setChartData] = useState<any[]>();
    const [loading, setLoading] = useState(true);

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId/stock',
        exact: false,
        strict: false
    });

    const getRevenueSegmentationFourYear = async () => {
        await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/get-four-year-revenue-segmentation",
            {
                params: {
                    year: currentYear
                }
            })
            .then(r => {
                setGraphData(buildGraphs(r.data))
                setChartData(r.data)
                
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            });
    };

    const buildGraphs = (productData: { [key: string]: { [year: string]: number } }): any[] => {
        const graphData: any[] = [];

        for (const product in productData) {
            const productDataPoints: any[] = [];
            for (const year in productData[product]) {
                productDataPoints.push({
                    x: parseInt(year),
                    y: productData[product][year]
                });
            }
            productDataPoints.sort((a, b) => a.x - b.x);
            graphData.push({
                name: product,
                data: productDataPoints
            });
        }
        return graphData;
    };

    const transformStackedBarChart = (productData: { [key: string]: { [year: string]: number } }): any => {
        const transformedData = {};

        Object.keys(productData).forEach((key) => {
            Object.keys(productData[key]).forEach((year) => {
                if (!transformedData[year]) {
                    transformedData[year] = {};
                }
                transformedData[year][key] = {"x": key, "y": productData[key][year]};
            });

        });

        console.log(transformedData)

        return transformedData;
    }

    useEffect(() => {
        setLoading(true)
        getRevenueSegmentationFourYear()
    }, []);


    return (
        <Container fluid style={{paddingLeft: 0, paddingRight: 0, marginTop: 15, overflowX: "hidden"}}>
            {loading ? <IqSmallLoadingIcon/> :
                <div className="flex-col">
                    <div className="flex-row">
                        <div style={{minHeight: 450}} className="organisation-card w-50">
                            <RevenueSegmentationLineSeriesChart graphData={graphData}/>
                        </div>
                        <div style={{minHeight: 450}} className="organisation-card w-50">
                            {/*<RevenueSegmentationStackedBarChart chartData={chartData}/>*/}
                            <RevenueSegmentationStackedPercentageChart chartData={chartData} percentageMode={false}/>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div style={{minHeight: 450}} className="organisation-card w-50">
                            <RevenueSegmentationStackedPercentageChart chartData={chartData} percentageMode={true}/>
                        </div>
                    </div>
                </div>
            }
        </Container>
    )
}

export default OrganisationStockProduct;