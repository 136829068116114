import React, { useContext } from "react";
import translate from "../../i18n/translate";
import {Col, Container, Row } from "react-bootstrap";
import UserOrganisationContext from "../../context/organisation/OrganisationContext";
import {useHistory, useLocation, useRouteMatch } from "react-router-dom";

const StockMarketSwitch: React.FC = () => {

    const {
        organisation
    } = useContext(UserOrganisationContext);


    let location = useLocation();
    let history = useHistory();
    let match = useRouteMatch();

    return (
        <Container fluid style={{ paddingTop: 15, marginRight: "auto", paddingLeft: 15}}>
            <Row className="" style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                <div style={{marginLeft: "auto", marginRight: "auto"}} className="iq-button-group">
                    <button className={window.location.pathname.includes("stock-data") ? "selected-inverted" : ""}
                            onClick={() => {history.push(`${match.url}/stock-data`)  }}>
                        Stock
                    </button>
                    {organisation.primaryType === 'Vendor' && (
                        <button className={window.location.pathname.includes("product") ? "selected-inverted" : ""}
                                onClick={() => { history.push(`${match.url}/product`) }}>
                            Product
                        </button>
                    )}
                </div>
            </Row>
        </Container>




    );
}

export default StockMarketSwitch;