import React, {Dispatch, useContext, useEffect, useRef, useState} from 'react';
import {matchPath, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import './OrganisationSummary.css';
import SubmenuContext from '../../context/SubmenuContext';
import {
    faBuilding,
    faCommentDollar,
    faExclamationCircle,
    faFileContract,
    faFilePdf,
    faFileWord,
    faHandshake,
    faMicrochip,
    faNewspaper,
    faPercentage,
    faRss,
    faTools,
    faUsers,
    faLandmark
} from '@fortawesome/free-solid-svg-icons';
import OrganisationSummary from '../organisation/OrganisationSummary';
import OrganisationEditor from '../organisation/OrganisationEditor';
import Container from 'react-bootstrap/Container';
import Organisation from '../../model/organisation/Organisation';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import {contractsHostName, organisationHostname, socialHostName} from '../../utils/Configuration';
import axios from 'axios';
import OrganisationHeader from './OrganisationHeader';
import OrganisationFinancials from './OrganisationFinancials';
import OrganisationNews from './OrganisationNews';
import OrganisationPeople from "./people/OrganisationPeople";
import OrganisationTechStack from './OrganisationTechStack';
import OrganisationShareholders from './OrganisationShareholders';
import PageNotFound from "../common/PageNotFound";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert} from 'react-bootstrap';
import IqLoadingIcon from '../common/IqLoadingIcon';
import ScrollPaginationContext from "../../context/ScrollPaginationContext";
import {hasUserRole, Role} from '../../utils/Security';
import SocialHandles from '../../model/organisation/social/SocialHandles';
import OrganisationSocialContext from '../../context/organisation/OrganisationSocialContext';
import ListNavigation from './ListNavigation';
import OrganisationChannelPartners from './channel-partners/OrganisationChannelPartners';
import OrganisationSocial from "./social/OrganisationSocial";
import DigitalMaturityScore from '../../model/organisation/DigitalMaturityScore';
import SearchContext from '../../context/SearchContext';
import PublicSectorSpend from '../publicsector/contracts/PublicSectorSpend';
import ContractsSearch from "../publicsector/contracts/ContractsSearch";
import CaseStudy from "../../model/organisation/CaseStudy";
import OrganisationCaseStudies from "./casestudy/OrganisationCaseStudies";
import YoutubeVideo from "../../model/organisation/social/YoutubeVideo";
import OrganisationPublicSectorContainer from './public-sector/OrganisationPublicSectorContainer';
import WhitePaper from "../../model/organisation/WhitePaper";
import InvestorRelations from "../../model/organisation/InvestorRelations";
import OrganisationWhitePaper from "./whitepaper/OrganisationWhitePaper";
import OrganisationYouTube from './social/OrganisationYouTube';
import NewOrganisationEditor from './NewOrganisationEditor';
import { StateHandler } from '../../model/organisation/editor/StateHandler';
import OrganisationCaseStudiesContainer from './casestudies/OrganisationCaseStudiesContainer';
import OrganisationStockMarket from './OrganisationStockMarket';
import StockMarketContainer from './StockMarketContainer';



const OrganisationContainer: React.FC = () => {

    let match = useRouteMatch();
    let location = useLocation();
    let history = useHistory();

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId',
        exact: false,
        strict: false
    });

    const intialStateHandler = {loading: false, success:false, error: {state: false, message: ""}}

    const [caseStudiesState, setCaseStudiesState] = useState<StateHandler>(intialStateHandler);
    const [whitePaperState, setWhitePaperState] = useState<StateHandler>(intialStateHandler);
    const [investorRelationsState, setInvestorRelationsState] = useState<StateHandler>(intialStateHandler);

    const [investorRelationsResponse, setInvestorRelationsResponse] = useState<InvestorRelations[]>();
    const [caseStudiesResponse, setCaseStudiesResponse] = useState<CaseStudy[]>();
    const [whitePaperResponse, setWhitePaperResponse] = useState<WhitePaper[]>();

    const[whitePaperSearchTerm, setWhitePaperSearchTerm] = useState<string>("");
    const [caseStudySearchTerm, setCaseStudySearchTerm] = useState<string>("");

    const [organisation, setOrganisation] = useState<Organisation>({} as Organisation);
    const [loadingYoutubeCaseStudies, setLoadingYoutubeCaseStudies] = useState<boolean>(false);
    const [youtubeCaseStudies, setYoutubeCaseStudies] = useState<YoutubeVideo[]>([]);
    const [loadingMarketUpdate, setLoadingMarketUpdate] = useState<boolean>(false);

    const isLLP = (organisation: Organisation) => {
        if (!organisation || !organisation.id) {
            return false;
        }

        return organisation.id.startsWith("UKOC") || organisation.id.startsWith("UKSO") || organisation.id.startsWith("UKNC");
    }
    
    const fetchWhitePaperORCaseStudies = async (url: string,
                                                setStateData: Dispatch<StateHandler>, stateData: StateHandler,
                                                setResponse: Dispatch<any>, searchTerm: string) => {

        setStateData({...stateData, loading: true});

        let params = {};
        if(searchTerm){
            params['q'] = searchTerm;
        }
        await axios.get(url, {params: params})
            .then(r => setResponse(r.data))
            .finally(() => setStateData({...stateData, loading: false}))
        
    }


    useEffect(() => {
        marketUpdateTicker()
    }, [childMatchedPath?.params.organisationId]);

    useEffect(() => {
        fetchWhitePaperORCaseStudies(
            socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/case-studies",
            setCaseStudiesState, caseStudiesState, setCaseStudiesResponse, caseStudySearchTerm)
    }, [caseStudySearchTerm]);

    useEffect(() => {
        fetchWhitePaperORCaseStudies(
            socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/whitepaper",
            setWhitePaperState, whitePaperState, setWhitePaperResponse, whitePaperSearchTerm)
    }, [whitePaperSearchTerm]);

    useEffect(() => {
        const fetchYoutubeCaseStudies = async () => {
            setLoadingYoutubeCaseStudies(true);
            await axios.get(socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/case-studies/youtube")
                .then(r => setYoutubeCaseStudies(r.data))
                .finally(() => setLoadingYoutubeCaseStudies(false));
        }

        fetchYoutubeCaseStudies();
    }, []);

    // useEffect(() => {
    //
    //     const fetchJobs = () => {
    //
    //        let request = {
    //             companyName: organisation.companyName,
    //             countryCode: organisation.countryCode,
    //             jobTitle: "",
    //             organisationId: organisation.id
    //         }
    //
    //         console.log(request)
    //
    //         axios.post(socialHostName + "jobs/company-search", request)
    //             .then(() => setJobsRequested(true))
    //             .catch((error) => {console.log("Error Occured in Jobs Exec Summary Call")});
    //     };
    //
    //     if(!jobsRequested){
    //         fetchJobs();
    //     }
    //
    // }, [organisation]);

    const [socialHandles, setSocialHandles] = useState<SocialHandles>({} as SocialHandles);
    const [digitalMaturityScore, setDigitalMaturityScore] = useState<DigitalMaturityScore>({} as DigitalMaturityScore);

    const [loading, setLoadingState] = useState<boolean>(true);
    const [loadingSocials, setLoadingSocials] = useState<boolean>(true);
    const [isVendor, setIsVendor] = useState<boolean>(false);

    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const params = new URLSearchParams(location.search);
    const listId = params.get("list");
    const sort = params.get("sort");

    // For gathering Contracts Count
    // New Filters Definition
    const [showFollowed] = useState<boolean>(false);
    const [searchTerm] = useState<string | undefined>(undefined);
    const [statusFilters] = useState<string[]>(["complete"]);
    const [buyerFilters] = useState<string[]>([]);
    const [supplierFilters] = useState<string[]>([]);
    const [cpvFilters] = useState<string[]>([]);
    const [minValue] = useState<number | undefined>(undefined);
    const [maxValue] = useState<number | undefined>(undefined);
    const [startDateFrom] = useState<string | undefined>(undefined);
    const [startDateTo] = useState<string | undefined>(undefined);
    const [endDateFrom] = useState<string | undefined>(undefined);
    const [endDateTo] = useState<string | undefined>(undefined);
    const [publishedDateFrom] = useState<string | undefined>(undefined);
    const [publishedDateTo] = useState<string | undefined>(undefined);
    const [smallMediumEnterprises] = useState<boolean | undefined>(undefined);
    const [VoluntaryCommunitySocialEnterprise] = useState<boolean | undefined>(undefined);
    const [gbCompaniesHouseNumber, setGbCompaniesHouseNumber] = useState<string | undefined>(undefined);

    const [contractsCount, setContractsCount] = useState<number>();
    const [gcloudProductsCount, setgcloudProductsCount] = useState<number>();
    const [ccsFrameworksCount, setCCSFrameworksCount] = useState<number>();
    const [fundingCount, setFundingCount] = useState<number>();
    const [spendCount, setSpendCount] = useState<number>();

    const [hasTicker, setHasTicker] = useState<boolean>(false);


    const setLoading = (isLoading: boolean) => {
        setLoadingState(isLoading);
    };

    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);
    const {setSearchQuery} = useContext(SearchContext);

    // const [logoLoaded, setLogoLoaded] = useState<boolean>(false);
    useEffect(() => {
        setSearchQuery("");
    }, [organisation]);

    const getOrganisationLogo = async () => {
        axios.get(organisationHostname + "organisation/get-logo/" + childMatchedPath?.params.organisationId,
        ).then((response) => {
            if (response.status === 200) {
                console.log("Logo Image Successfully Updated")
                // organisation.imageUrl = response.data;
                // setOrganisation(organisation);
            }
        }).catch(error => {
            if (error.response.status === 400) {
            }
            console.error(error.message);
        })
    }

    const hasTickerData = async () => {

        await axios.get(organisationHostname + "organisation/" + organisation.id + "/has-ticker")
            .then(r => {
                return true;
            })
            .catch(err => console.error(err));
        return false;
    };


    const marketUpdateTicker = async () => {
        const queryParams = {
            manualUpdate: false,
        };

        await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/market-update", {params: queryParams})
            .catch(err => console.error(err));
        setLoadingMarketUpdate(true)
    };

    // Fetch Company and if there's an error, catch it and set an Error message to display to the user
    const fetchCompany = async () => {
        await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId)
            .then(response => setOrganisation(response.data))
            .catch(error => {
                if (error.response.status == 404 || error.response.status == 400) {
                    setNotFound(true);
                    setErrorMessage(error.response.data.title);
                } else {
                    setError(true);
                    setErrorMessage(error.response.data.title);
                }
            })
            .finally(() => setLoading(false))
    };

    const wrapperAsync = async() =>  {
        await getOrganisationLogo();
        fetchCompany();
    }



    useEffect(() => {
        setLoading(true);
        wrapperAsync();
    }, [childMatchedPath?.params.organisationId]);


    useEffect(() => {
        if (organisation != null) {
            setLoadingSocials(true);
            const fetchSocialHandles = async () => {
                await axios.get(socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/social-handles")
                    .then((response) => setSocialHandles(response.data))
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => setLoadingSocials(false))
            };
            fetchSocialHandles();
        }
    }, [organisation])


    useEffect(() => {
        if (organisation != null) {
            const fetchVendorCheck = async () => {
                await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/is-vendor")
                    .then((response) => setIsVendor(response.data))
                    .catch(error => {
                        console.error(error);
                    })
            };
            fetchVendorCheck();
        }
    }, [organisation])

    useEffect(() => {
        if (organisation != null) {
            const fetchInvestorRelations = async () => {
                await axios.get(socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/investor-relations")
                    .then((response) => setInvestorRelationsResponse(response.data))
                    .catch(error => {
                        console.error(error);
                    })
            };
            fetchInvestorRelations();
        }
    }, [organisation])

    useEffect(() => {
        if (organisation != null) {
            const computeDigitalMaturityScore = async () => {
                await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/digital-maturity-score")
                    .then((response) => setDigitalMaturityScore(response.data))
                    .catch(error => {
                        console.error(error);
                    })
            };
            computeDigitalMaturityScore();
        }
    }, [organisation])

    const getFilters = () => {
        return {
            followed: showFollowed,
            searchTerm: searchTerm ? searchTerm : null,
            status: statusFilters,
            cpvCode: cpvFilters,
            buyerName: buyerFilters,
            supplierName: supplierFilters,
            minValue: minValue ? (minValue > 0 ? minValue : null) : null,
            maxValue: maxValue ? (maxValue > 0 ? maxValue : null) : null,
            minStartDate: startDateFrom ? startDateFrom : null,
            maxStartDate: startDateTo ? startDateTo : null,
            minEndDate: endDateFrom ? endDateFrom : null,
            maxEndDate: endDateTo ? endDateTo : null,
            minPublishedDate: publishedDateFrom ? publishedDateFrom : null,
            maxPublishedDate: publishedDateTo ? publishedDateTo : null,
            sme: smallMediumEnterprises ? smallMediumEnterprises : null,
            vcse: VoluntaryCommunitySocialEnterprise ? VoluntaryCommunitySocialEnterprise : null,
            gbCohNumber: gbCompaniesHouseNumber ? gbCompaniesHouseNumber : null
        };
    };

    // If we have a UK Organisation, check if it has any contracts, if it does have contracts then set count which will trigger the showing of the Public Sector Contracts menu
    useEffect(() => {
        let companyCodeToSearch: string = "";

        if (location.pathname.includes("/organisation/UK")) {
            companyCodeToSearch = "GB-COH-" + location.pathname.replace("/organisation/UK", "");
            if (companyCodeToSearch.includes("/")) {
                companyCodeToSearch = companyCodeToSearch.substring(0, companyCodeToSearch.lastIndexOf("/") + 1).replace("/", "");
            }
            setGbCompaniesHouseNumber(companyCodeToSearch);
        } else {
            companyCodeToSearch = "GB-COH-" + organisation.companyNumber;
            setGbCompaniesHouseNumber(companyCodeToSearch);
        }

        const getContractsCount = async () => {
            if (organisation.companyName !== null && organisation.companyName !== undefined) {
                // Get Filters to send to POST request
                let myFilters = getFilters();
                myFilters.gbCohNumber = companyCodeToSearch;
                myFilters.buyerName = [];

                // Increment number by count returned
                let counter = 0;

                await axios.post(contractsHostName + "elastic/search/count", myFilters)
                    .then(r => {
                        counter = counter + r.data
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                        }
                        console.error(error.message);
                    });

                // Reset COH Number and search by Buyer name
                myFilters.gbCohNumber = null;
                myFilters.buyerName = [organisation.companyName];

                await axios.post(contractsHostName + "elastic/search/count", myFilters)
                    .then(r => {
                        counter = counter + r.data
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                        }
                        console.error(error.message);
                    });

                setContractsCount(counter);
                setLoading(false);
            }
        };
        const getCCSFrameworksCount = async () => {
            let counter = 0
            await axios.get(contractsHostName + `ccs-framework/organisation/${childMatchedPath?.params.organisationId}/count`)
                .then(r => counter += r.data)
                .catch(error => {
                    console.error(error.message);
                });

            setCCSFrameworksCount(counter);
        }

        const getGCloudProductsCount = async () => {

            let counter = 0
            await axios.get(contractsHostName + `g-cloud/${childMatchedPath?.params.organisationId}/products/count`)
                .then(r => counter += r.data)
                .catch(error => {
                    console.error(error.message);
                });

            setgcloudProductsCount(counter);
        }

        const getFundingCount = async () => {
            let counter = 0
            await axios.get(contractsHostName + `public-research-funding/${childMatchedPath?.params.organisationId}/count`)
                .then(r => counter += r.data)
                .catch(error => {
                    console.error(error.message);
                });

            setFundingCount(counter);
        }

        const getSpendCount = async () => {

            await axios.get(organisationHostname + `organisation/${childMatchedPath?.params.organisationId}/spend/count`)
                .then(r => {
                    setSpendCount(r.data)
                })
                .catch(error => {
                    if (error.response.status === 400) {
                    }
                    console.error(error.message);
                });
        }

        getContractsCount();
        getGCloudProductsCount();
        getCCSFrameworksCount();
        getFundingCount();
        getSpendCount();
    }, [childMatchedPath?.params.organisationId]);

    useEffect(() => {
        if (organisation) {
            setSubmenuTitle("Organisation");
            let initialSubmenu = [
                {
                    name: "organisation.summary.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + (listId ? "?list=" + listId : ""),
                    icon: faBuilding
                },
                {
                    name: "organisation.people.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/people",
                    icon: faUsers
                },
                {
                    name: "organisation.techstack.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/techstack",
                    icon: faMicrochip
                },
                {
                    name: "organisation.news.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/news",
                    icon: faNewspaper
                },
                {
                    name: "organisation.social.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/social",
                    icon: faRss
                }
            ];

            if (organisation?.countryCode === "UK" && organisation?.primaryType !== "PublicSector" && !isLLP(organisation)) {
                initialSubmenu.push({
                    name: "organisation.shareholders.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/shareholders",
                    icon: faFileContract
                });
            }

            if (organisation?.primaryType === "PublicSector" && spendCount > 0) {
                initialSubmenu.push({
                    name: "organisation.spend.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/spend",
                    icon: faCommentDollar
                });
            }

            if (isVendor) {
                initialSubmenu.push({
                    name: "organisation.channelpartners.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/channel-partners",
                    icon: faHandshake
                });
            }

            if (
                (contractsCount && contractsCount > 0 ||
                    gcloudProductsCount && gcloudProductsCount > 0 ||
                    ccsFrameworksCount && ccsFrameworksCount > 0 ||
                    fundingCount && fundingCount > 0) && organisation.countryCode === "UK"
            ) {
                initialSubmenu.push({
                    name: "Public Sector",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/public-sector-organisation/contracts",
                    icon: faBuilding
                });
            }

            if (caseStudiesResponse?.length > 0 ||
                youtubeCaseStudies?.length > 0 ||
                whitePaperResponse?.length > 0 ||
                investorRelationsResponse?.length > 0) {
                initialSubmenu.push({
                    name: "Documents",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/documents",
                    icon: faFilePdf
                });
            }
            if (hasUserRole(Role.ADMIN)) {
                initialSubmenu.push({
                    name: "organisation.edit.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/edit",
                    icon: faTools
                });
            }

            if (hasFinancials() && organisation?.primaryType !== "PublicSector") {
                let itemExists = initialSubmenu.find((i: any) => i.name === "Financials");
                if (!itemExists) {
                    initialSubmenu.splice(1, 0, {
                        name: "organisation.financials.title",
                        url: "/organisation/" + childMatchedPath?.params.organisationId + "/financials",
                        icon: faPercentage
                    });
                }
            }

            if (organisation.countryCode === "US" && hasTickerData() &&  loadingMarketUpdate) {
                initialSubmenu.push({
                    name: "organisation.stockmarket.title",
                    url: "/organisation/" + childMatchedPath?.params.organisationId + "/stock",
                    icon: faLandmark
                });
            }

            setSubmenuItems(initialSubmenu);
        }
        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, [organisation, contractsCount, caseStudiesResponse, whitePaperResponse, youtubeCaseStudies, gcloudProductsCount, loadingMarketUpdate, fundingCount, ccsFrameworksCount, spendCount, hasTicker]);



    const hasFinancials = (): boolean => {
        let yearValues = organisation.financialSummary;
        return yearValues && yearValues.length > 0;
    };

    let scroller = useRef<any>();
    const [page, setPageState] = useState<number>(0);
    const setPage = (p: number) => {
        setPageState(p);
    };

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;
            if (currentScroll === maxScroll) {
                setPage(page + 1);
            }
        }
    };

    useEffect(() => {
        const fetchNylasToken = async () => {
            if (window.location.href.includes("callback")) {
                const query = new URLSearchParams(window.location.href);
                axios.post(socialHostName + "nylas/register-token", query.get('access_token'),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then((response) => {
                    if (response.status === 200) {
                    }
                }).catch(error => {
                    if (error.response.status === 400) {
                    }
                    console.error(error.message);
                }).finally(() => {
                    setLoading(false);
                    history.go(-3);
                });
            }
        }
        fetchNylasToken();
    }, []);
    
return (
    <ScrollPaginationContext.Provider value={{page, setPage}}>
        {/* Removed from Div Style -  overflowY: "scroll", overflowX: "hidden" */}
        <div ref={scroller} onScroll={handleScroll} className="container-div-with-y-scroll">
            <UserOrganisationContext.Provider value={{
                organisation,
                setOrganisation,
                caseStudiesResponse,
                setCaseStudiesResponse,
                caseStudySearchTerm,
                setCaseStudySearchTerm,
                caseStudiesState,
                setCaseStudiesState,
                youtubeCaseStudies,
                setYoutubeCaseStudies,
                whitePaperResponse,
                setWhitePaperResponse,
                whitePaperSearchTerm,
                setWhitePaperSearchTerm,
                whitePaperState,
                setWhitePaperState,
                investorRelationsState,
                setInvestorRelationsState,
                investorRelationsResponse,
                setInvestorRelationsResponse,
                loading,
                setLoading,
                fetchCompany
            }}>
                <OrganisationSocialContext.Provider
                    value={{
                        socialHandles,
                        setSocialHandles,
                        loadingSocials,
                        setLoadingSocials,
                        digitalMaturityScore
                    }}>
                    <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
                        {!loading ?
                            !notFound && !error
                                ?
                                <div>
                                    <OrganisationHeader/>
                                    {listId && (
                                        <div>
                                            <ListNavigation listId={listId}
                                                            organisationId={childMatchedPath?.params.organisationId}
                                                            sort={sort}/>
                                        </div>
                                    )}
                                    <Switch>
                                        <Route exact path={`${match.url}/:organisationId`}>
                                            <OrganisationSummary temporary={false}/>
                                        </Route>
                                        {hasFinancials() && (
                                            <Route strict path={`${match.url}/:organisationId/financials`}>
                                                <OrganisationFinancials/>
                                            </Route>
                                        )}
                                        <Route strict path={`${match.url}/:organisationId/news`}>
                                            <OrganisationNews/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/edit`}>
                                            <NewOrganisationEditor/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/people`}>
                                            <OrganisationPeople/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/stock`}>
                                            <StockMarketContainer />
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/social`}
                                               component={OrganisationYouTube}/>
                                        <Route strict path={`${match.url}/:organisationId/documents`}
                                               component={OrganisationCaseStudiesContainer}/>
                                        <Route strict path={`${match.url}/:organisationId/whitepaper`}
                                               component={OrganisationWhitePaper}/>
                                        <Route strict path={`${match.url}/:organisationId/techstack`}>
                                            <OrganisationTechStack/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/channel-partners`}>
                                            <OrganisationChannelPartners/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/shareholders`}>
                                            <OrganisationShareholders/>
                                        </Route>
                                        <Route strict path={`${match.url}/:organisationId/public-sector-organisation`}>
                                            <OrganisationPublicSectorContainer gCloudProductTotal={gcloudProductsCount}></OrganisationPublicSectorContainer>
                                        </Route>
                                        {organisation?.primaryType === "PublicSector" &&
                                            <>
                                                <Route strict path={`${match.url}/:organisationId/contracts`}>
                                                    <ContractsSearch achieved={false}/>
                                                </Route>
                                                <Route strict path={`${match.url}/:organisationId/spend`}>
                                                    <PublicSectorSpend/>
                                                </Route>
                                            </>
                                        }
                                        {organisation?.primaryType !== "PublicSector" &&
                                            <>
                                                <Route strict
                                                       path={`${match.url}/:organisationId/achieved-contracts`}>
                                                    <ContractsSearch achieved={true}/>
                                                </Route>
                                            </>
                                        }
                                        <Route component={PageNotFound}/>
                                    </Switch>
                                </div>
                                :
                                <>
                                    {notFound &&
                                        <div>
                                            <Alert variant="primary" style={{width: "100%", marginTop: 25}}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB"
                                                                     size="2x"/>
                                                    {errorMessage ?
                                                        <span style={{marginLeft: 15}}>The company you are trying to reach could not be found in our database.<br/>
                                                                Reason: {errorMessage}<br/>
                                                                <span
                                                                    onClick={() => history.push("/dashboard")} style={{
                                                                    color: "#42A6EE",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer"
                                                                }}>Go back</span> to the dashboard.</span>
                                                        :
                                                        <span style={{marginLeft: 15}}>The company you are trying to reach could not be found in our database.<br/>
                                                                <span
                                                                    onClick={() => history.push("/dashboard")} style={{
                                                                    color: "#42A6EE",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer"
                                                                }}>Go back</span> to the dashboard.</span>
                                                    }
                                                </div>
                                            </Alert>
                                        </div>}
                                    {error &&
                                        <div>
                                            <Alert variant="danger" style={{width: "100%", marginTop: 25}}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB"
                                                                     size="2x"/>
                                                    {errorMessage ?
                                                        <span
                                                            style={{marginLeft: 15}}>An unexpected problem occurred.<br/>
                                                                Reason: {errorMessage}<br/>
                                                            Please, <span
                                                                onClick={() => history.push("/dashboard")} style={{
                                                                color: "#42A6EE",
                                                                textDecoration: "underline",
                                                                cursor: "pointer"
                                                            }}>return</span> to the dashboard.</span>
                                                        :
                                                        <span
                                                            style={{marginLeft: 15}}>An unexpected problem occurred.<br/>
                                                            Please, <span
                                                                onClick={() => history.push("/dashboard")} style={{
                                                                color: "#42A6EE",
                                                                textDecoration: "underline",
                                                                cursor: "pointer"
                                                            }}>return</span> to the dashboard.</span>
                                                    }
                                                </div>
                                            </Alert>
                                        </div>}
                                </>
                            :
                            <IqLoadingIcon/>
                        }
                    </Container>
                </OrganisationSocialContext.Provider>
            </UserOrganisationContext.Provider>
        </div>
    </ScrollPaginationContext.Provider>
    );
}

export default OrganisationContainer;
