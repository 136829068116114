import React, {useContext, useEffect, useState} from 'react';
import {Row, Image, Col, Container, Card, Table, Alert} from 'react-bootstrap';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import './OrganisationStockMarket.css';
import Organisation from '../../model/organisation/Organisation';
import Select from 'react-select';
import Ticker from '../../model/organisation/stockmarket/TickerData';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import {SelectOption} from '../news/GlobalNews';
import QuarterlyStatement from '../../model/organisation/stockmarket/QuarterlyStatement';
import QuarterlyStatementResponse from '../../model/organisation/stockmarket/QuarterlyStatementResponse';
import IsinData from '../../model/organisation/stockmarket/IsinData';
import 'd3-time-format'
import * as d3 from 'd3';
import "./OrganisationStockPriceBar.css";



import {
    faArrowUp,
    faArrowDown,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatLocale} from 'd3-format';
import OrganisationLineSeriesChart from './common/OrganisationLineSeriesChart';
import GraphData from '../../model/organisation/stockmarket/GraphData';
import translate from '../../i18n/translate';
import StockMarketLineSeriesChart from './StockMarketLineSeriesChart';
import {match, matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import OrganisationStockPriceBar from './OrganisationStockPriceBar';



const OrganisationStockMarket: React.FC = () => {


    let location = useLocation();
    let history = useHistory();
    let match = useRouteMatch();

    const {
        organisation,
    } = useContext(UserOrganisationContext);

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId/stock',
        exact: false,
        strict: false
    });
    
    const [quarterlyStatements, setQuarterlyStatements] = useState<QuarterlyStatementResponse>()
    const [isin, setIsin] = useState<IsinData>();
    const [isinHistoric, setisinHistoric] = useState<GraphData[]>([])
    const [graphData, setGraphData] = useState([] as any);
    const [loading, setLoading] = useState<boolean>(true)

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [isinNotFound, setIsinNotFound] = useState<boolean>(false);
    const [graphNotFound, setGraphNotFound] = useState<boolean>(false);

    const getQuarterlyStatements = async () => {

        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/quarterly-statements",
            {
                params: {
                    year: currentYear
                }
            })
            .then(r => {
                setQuarterlyStatements(r.data);
            })
            .catch(err => setError(true));
    };

    const getIsinData = async () => {
        await axios.get(organisationHostname + "organisation/ticker-cik/" + childMatchedPath?.params.organisationId)
            .then(r => {
                setIsin(r.data);
            })
            .catch(err => {
                console.error(err);
                setIsinNotFound(true)
            });
    };

    const getGraphData = async () => {
        await axios.get(organisationHostname + "organisation/ticker-graph/" + childMatchedPath?.params.organisationId)
            .then(r => {
                if (r.data.length === 0){
                    setGraphNotFound(true)
                }
                setisinHistoric(r.data);
                buildGraphs(r.data)
            })
            .catch(err => {
                console.error(err);
                setGraphNotFound(true)
            });
    }

    const buildGraphs = (isinData: GraphData[]) => {
        isinData.forEach((oyv: { price: any; dateFound: any; }) => {
            setGraphData((graphData: any) => [
                    ...graphData,
                    {
                        y: oyv.price,
                        x: new Date(oyv.dateFound).getTime()
                    }
                ]
                    .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)) // sort by year in ascending order
            );
        });
    }


    enum CURRENCY {
        US = "$",
        UK = "£",
        FR = "€",
        DE = "€"
    }

    enum CURRENCY_TYPE {
        US = "USD",
        UK = "GBP",
        FR = "EUR",
        DE = "EUR"
    }

    const customLocale = formatLocale({
        currency: [CURRENCY[organisation.countryCode], ""],
        decimal: ".",
        thousands: ",",
        grouping: [3]
    });

    const timeFormat = d3.timeFormat("%d %b, %H:%M")

    const TickerCard = () => {

        const priceBeforeChange = isin.changes > 0 ? isin.price - isin.changes : isin.price + isin.changes
        console.log(priceBeforeChange);
        const changePercentage = (((isin.price - priceBeforeChange) / priceBeforeChange) * 100).toFixed(2)

        return (
            <div style={{minHeight: 285}} className="organisation-card w-50">
                <div style={{justifyContent: "space-between"}} className="flex-row ">
                    <div style={{flex: 1}} className="flex-col">
                        <div className="flex-row">
                            <div style={{textAlign: "start"}} className="flex-col w-full">
                                <div className="organisation-card-label">Stock Overview</div>
                                <div>
                                    Mkt Cap: {customLocale.format('$,.3s')(+isin.mktCap).replace(/G/, "B")}
                                </div>
                                <div>
                                    Div Yield: {((isin.lastDiv / isin.price) * 100).toFixed(2)}%
                                </div>
                                <div>
                                    Ticker: {isin.symbol}
                                </div>
                            </div>
                            <div style={{textAlign: "center", alignItems: "center"}} className="flex-col w-full">
                                <div className="organisation-card-label">Current Share Price</div>
                                <div className="flex-row  justify-center">
                                    <span className="isin-price">{isin.price}</span>
                                    <span className="flex-end pt-4"
                                          style={{color: "#929292"}}>{[CURRENCY_TYPE[organisation.countryCode]]}</span>
                                </div>
                                <div className="flex-row  justify-center align-center">
                                                     <span
                                                         className={isin.changes > 0 ? "postive-percent" : "negative-percent"}>{isin.changes > 0 ? "+" : ""}{isin.changes.toFixed(2)}</span>
                                    <FontAwesomeIcon icon={isin.changes > 0 ? faArrowUp : faArrowDown}
                                                     color={isin.changes > 0 ? "#90EE90FF" : "#CD5C5CFF"}/>

                                    <span
                                        className={isin.changes > 0 ? "postive-percent" : "negative-percent"}>{isin.changes > 0 ? "+" : ""}{changePercentage}%</span>
                                    <FontAwesomeIcon icon={isin.changes > 0 ? faArrowUp : faArrowDown}
                                                     color={isin.changes > 0 ? "#90EE90FF" : "#CD5C5CFF"}/>
                                </div>
                            </div>
                            <div style={{textAlign: "end", alignItems: "end"}} className="flex-col w-full">
                                <div className="organisation-card-label">52-Week Range</div>
                                <div>
                                    52wk-Low: {parseFloat(isin.range.split("-")[0]).toFixed(2)}
                                </div>
                                <div>
                                    52wk-High: {parseFloat(isin.range.split("-")[1]).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{justifyContent: "space-between"}} className="flex-row pt-1">

                    <div style={{flex: 1}} className="flex-col">
                        <div className="flex-row">
                            <Table borderless>
                                <thead>
                                <tr>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr style={{borderTop: "none"}}>
                                    <td style={{width: '100%', justifyContent: "center"}}>
                                        <div className="flex-row" style={{justifyContent: "center"}}>
                                            <span
                                                className="organisation-card-label">52-Week Range</span>

                                        </div>
                                        <div className="flex-row" style={{justifyContent: "space-between"}}>
                                            <span
                                                className="iq-stock-price-mini-text ">Low</span>
                                            <span
                                                className="iq-stock-price-mini-text ">High</span>
                                        </div>
                                        <div className="flex-row">
                                            <OrganisationStockPriceBar price={isin.price}
                                                                       low={parseFloat(isin.range.split("-")[0])}
                                                                       high={parseFloat(isin.range.split("-")[1])}></OrganisationStockPriceBar>
                                        </div>
                                        <div className="flex-row" style={{justifyContent: "space-between"}}>
                                            <span
                                                className="iq-stock-price-mini-text ps-2">${parseFloat(isin.range.split("-")[0]).toFixed(2)}</span>
                                            <div className="flex-row pt-4">
                                                <div style={{height: "20px", width: "20px", alignSelf: "center"}}
                                                     className="iq-stock-price-bar"></div>
                                                <span className="iq-stock-price-text" style={{textAlign: "left"}}>Current Price</span>
                                            </div>
                                            <span
                                                className="iq-stock-price-mini-text pe-2">${parseFloat(isin.range.split("-")[1]).toFixed(2)}</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="flex-row pt-3" style={{justifyContent: "center"}}>
                                                <span
                                                    style={{color: "#929292"}}>Last Updated {timeFormat(new Date(isin.lastModified))}</span>
                </div>

            </div>
        )
    }

    const QuarterlyCard = (key: string) => {

        const hasData = quarterlyStatements[key] === null ? false : true

        let hasRevenue;
        if (!hasData) {
            hasRevenue = false
        } else {
            hasRevenue = quarterlyStatements[key].revenue === null ? false : true
        }

        const cardStyle = {
            opacity: hasRevenue ? 1 : 0.3
        };

        const statement = quarterlyStatements[key]

        return (
            <Card style={cardStyle} className="w-25 header-card pt-3 pb-3">
                <span>
                    {key}
                </span>
                <span className="inner-card">
                  {statement?.month} {statement?.calendarYear}
                </span>
                <Table className="organisation-card-text inner-card">
                    <thead>
                    <tr>
                        <th>
                        </th>
                        <th>
                        </th>
                        <th>
                            Y/Y
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{textAlign: 'left'}} className="ps-4">Revenue</td>
                            {hasRevenue ?
                                <>
                                    <td style={{textAlign: 'left'}} >{customLocale.format('$,.3s')(+statement.revenue).replace(/G/, "B")}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <div
                                            className={statement.revenuePercentage.smallerYOY ? 'negative-percent' : 'postive-percent'}>
                                            {statement.revenuePercentage.percentage.toFixed(2)}%
                                        </div>
                                    </td>
                                </>
                                :
                                <>
                                    <td style={{textAlign: 'right'}}></td>
                                    <td style={{textAlign: 'left'}}></td>
                                </>
                            }
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}} className="ps-4" >Net Income</td>
                            {hasRevenue ?
                                <>
                                    <td style={{textAlign: 'left'}}>{customLocale.format('$,.3s')(+statement.netIncome).replace(/G/, "B")}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <div
                                            className={statement.netIncomePercentage.smallerYOY ? 'negative-percent' : 'postive-percent'}>
                                            {statement.netIncomePercentage.percentage.toFixed(2)}%
                                        </div>
                                    </td>
                                </>
                                :
                                <>
                                    <td style={{textAlign: 'right'}}></td>
                                    <td style={{textAlign: 'left'}}></td>
                                </>
                            }
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}} className="ps-4">Diluted EPS</td>
                            {hasRevenue ?
                                <>
                                    <td style={{textAlign: 'left'}}>{statement.epsdiluted}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <div
                                            className={statement.epsdilutedPercentage.smallerYOY ? 'negative-percent' : 'postive-percent'}>
                                            {parseFloat(statement.epsdilutedPercentage.percentage).toFixed(2)}%
                                        </div>
                                    </td>
                                </>
                                :
                                <>
                                    <td style={{textAlign: 'right'}}></td>
                                    <td style={{textAlign: 'left'}}></td>
                                </>
                            }
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}} className="ps-4"     >Net Margin</td>
                            {hasRevenue ?
                                <>
                                    <td style={{textAlign: 'left'}}>{statement.netProfitMargin == 0 ? "N/A" : statement.netProfitMargin.toFixed(2) + "%"}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <div
                                            className={statement.netProfitPercentage.smallerYOY ? 'negative-percent' : 'postive-percent'}>
                                            {statement.netProfitPercentage.percentage == 0 ? "N/A" : statement.netProfitPercentage.percentage.toFixed(2) + "%"}
                                        </div>
                                    </td>

                                </>
                                :
                                <>
                                    <td style={{textAlign: 'right'}}></td>
                                    <td style={{textAlign: 'left'}}></td>
                                </>
                            }
                        </tr>
                        </tbody>
                    </ Table>
            </Card>)
    }

    const runFunctions = async () => {
        setLoading(true)
        await getIsinData()
        await getQuarterlyStatements()
        await getGraphData()
        setLoading(false)
    }

    useEffect(() => {
        runFunctions()
    }, []);

    return (

        <Container fluid style={{paddingLeft: 0, paddingRight: 0, marginTop: 15, overflowX: "hidden"}}>
            {!error && !isinNotFound && !graphNotFound ?
                <>
                    {loading ? <IqSmallLoadingIcon/> :
                        <div className="flex-col">
                            <div className="flex-row">
                                <TickerCard />
                                <div style={{minHeight: 285}} className="organisation-card w-50">
                                    <div className="organisation-card-label">CHART</div>
                                    <div>
                                        <StockMarketLineSeriesChart data={graphData}
                                                                    colour="#52CAF9"
                                                                    title={"Price"}
                                                                    yAxisFormat={(value: any) => `${value}`}
                                                                    formatValue={(value: any) => {
                                                                        return customLocale.format('.2f')(value) + " $";
                                                                    }} />
                                    </div>

                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="organisation-card w-full">
                                    <div className="organisation-card-label">QUARTERS</div>
                                    <div className="flex-row align-center">
                                        {Object.keys(quarterlyStatements).map((quarter) => QuarterlyCard(quarter))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {isinNotFound &&
                        <div>
                            <Alert variant="primary" style={{width: "100%", marginTop: 25}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB"
                                                     size="2x"/>
                                    {errorMessage ?
                                        <span style={{marginLeft: 15}}>The company you are trying to access has no Isin data present in our database.<br/>
                                                                Reason: {errorMessage}<br/>
                                                                <span
                                                                    onClick={() => history.push("/dashboard")} style={{
                                                                    color: "#42A6EE",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer"
                                                                }}>Go back</span> to the dashboard.</span>
                                        :
                                        <span style={{marginLeft: 15}}>The company you are trying to access has no Isin data present in our database.<br/>
                                                                <span
                                                                    onClick={() => history.push("/dashboard")} style={{
                                                                    color: "#42A6EE",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer"
                                                                }}>Go back</span> to the dashboard.</span>
                                    }
                                </div>
                            </Alert>
                        </div>}
                    {graphNotFound && !isinNotFound &&
                        <div>
                            <Alert variant="primary" style={{width: "100%", marginTop: 25}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB"
                                                     size="2x"/>

                                    <span style={{marginLeft: 15}}>There was not data for the graph to load<br/>
                                                                <span
                                                                    onClick={() => history.push("/dashboard")} style={{
                                                                    color: "#42A6EE",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer"
                                                                }}>Go back</span> to the dashboard.</span>

                                </div>
                            </Alert>
                        </div>}
                    {error && !isinNotFound &&
                        <div>
                            <Alert variant="danger" style={{width: "100%", marginTop: 25}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB"
                                                     size="2x"/>
                                    {errorMessage ?
                                        <span
                                            style={{marginLeft: 15}}>An unexpected problem occurred.<br/>
                                                                Reason: {errorMessage}<br/>
                                                            Please, <span
                                                onClick={() => history.push("/dashboard")} style={{
                                                color: "#42A6EE",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}>return</span> to the dashboard.</span>
                                        :
                                        <span
                                            style={{marginLeft: 15}}>An unexpected problem occurred.<br/>
                                                            Please, <span
                                                onClick={() => history.push("/dashboard")} style={{
                                                color: "#42A6EE",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}>return</span> to the dashboard.</span>
                                    }
                                </div>
                            </Alert>
                        </div>}
                </>
            }

        </Container>
    )
}
export default OrganisationStockMarket;