import {DiscreteColorLegend, Hint, HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis} from "react-vis";
import React, {useEffect, useState} from "react";
import {AutoSizer} from "react-virtualized";
import {format} from "d3-format";
import DateFormatter from "../../utils/formatters/DateFormatter";
import axios from "axios";
import { organisationHostname } from "../../utils/Configuration";
import { matchPath } from "react-router-dom";
import {formatLocale} from 'd3-format';
import { COLOURS } from "../techsales/TechSales";


interface Props {
    graphData: any[]
}

const RevenueSegmentationLineSeriesChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();
    const [key, setKey] = useState<string>();


    enum CURRENCY {
        US = "$",
        UK = "£",
        FR = "€",
        DE = "€"
    }

    enum CURRENCY_TYPE {
        US = "USD",
        UK = "GBP",
        FR = "EUR",
        DE = "EUR"
    }

    const customLocale = formatLocale({
        currency: [CURRENCY.US, ""],
        decimal: ".",
        thousands: ",",
        grouping: [3]
    });


    const getGraphRange = () => {

        let highestValue = 0
        let lowestValue = 0

        Object.keys(props.graphData).map(key => {
            props.graphData[key].data.map(data => {
                if (data.y > highestValue){
                    highestValue = data.y
                } else if (data.y < lowestValue || lowestValue === 0){
                    lowestValue = data.y
                }

            })
        })

        const yMax = highestValue * 1.1;
        const yMin = lowestValue * 0.9;

        return [yMin, yMax];
    }

    const getColour = (key) => {
        if(key < COLOURS.length){
            return COLOURS[key]
        }
        return COLOURS[-key]
    }


    const calculateYearlyTotal = (product: any) => {
        let yearlyTotals = 0
        Object.keys(product.data).forEach( year => {
            yearlyTotals += Number(product.data[year].y)
        })
        return yearlyTotals;
    };


    const ProductLineChart = ({ graphData }) => {
        

        return (
            <div className="product-line-charts">
                    <AutoSizer>
                        {({ height, width }) => (
                            <div>
                                <XYPlot width={width} margin={{left: 60}} height={350} yDomain={getGraphRange()}>

                                    {hover && (
                                        <Hint value={hover}>
                                            <div className="chart-hint">
                                                <span>{key}</span>
                                                <div className="x">{hover.x}</div>
                                                <div
                                                    className="y">{customLocale.format('$,.3s')(+hover.y).replace(/G/, "B")}</div>
                                            </div>
                                        </Hint>
                                    )}


                                    <HorizontalGridLines/>

                                    {Object.keys(graphData).map(key => {
                                        return (

                                        <LineMarkSeries
                                            getNull={(d) => d.y !== null}
                                            color={getColour(key)}
                                            data={graphData[key].data}

                                            onValueMouseOver={(d) => {
                                                setHover(d)
                                                setKey(graphData[key].name)
                                            }}
                                            onValueMouseOut={(d) => {
                                                setHover(null);
                                                setKey(null);
                                            }}

                                        />
                                    )})}

                                    <XAxis
                                        tickFormat={(tick) => tick}
                                        title="Date"
                                        tickTotal={4}
                                    />
                                    <YAxis
                                        tickFormat={(tick) => `${customLocale.format('$,.3s')(+tick).replace(/G/, "B")}`}
                                        title={"Value"}/>
                                </XYPlot>
                                <div style={{alignItems: "center"}}>
                                    <DiscreteColorLegend orientation="horizontal" width={width}
                                                         items={Object.keys(graphData)?.sort((a,b) => calculateYearlyTotal(props.graphData[b]) - calculateYearlyTotal(props.graphData[a])).map(key => {
                                                             return {
                                                                 title: graphData[key].name,
                                                                     color: getColour(key),
                                                             }
                                                         })}
                                    />
                                </div>
                            </div>
                        )}
                    </AutoSizer>
            </div>
        );
    };
    getGraphRange()
    return (
        <ProductLineChart graphData={props.graphData}/>
    );
};

export default RevenueSegmentationLineSeriesChart;
