import React from "react";
import "./OrganisationStockPriceBar.css";

interface Props {
    price: number;
    low: number;
    high: number;
    label?: string;
}

const IqProgressBar: React.FC<Props> = (props: Props) => {

    const getWidth = (price, low, high) => {
        const range = high - low;
        const percentage = ((price - low) / range) * 100;
        return Math.min(percentage, 100);
    };

    
    return (
        <div className="iq-stock-price-container">
                <div className="iq-stock-price-bar" style={{marginLeft: `${getWidth(props.price, props.low, props.high)}%`}}>
                </div>
        </div>
    )
};

export default IqProgressBar;
