import { useContext, useEffect } from "react";
import UserOrganisationContext from "../../context/organisation/OrganisationContext";
import StockMarketSwitch from "./StockMarketSwitch";
import {Redirect, Route, useRouteMatch } from "react-router-dom";
import React from "react";
import OrganisationStockMarket from "./OrganisationStockMarket";
import OrganisationStockProduct from "./OrganisationStockProduct";


const StockMarketContainer: React.FC = () => {

    const {
        organisation
    } = useContext(UserOrganisationContext);

    let match = useRouteMatch();
    


    return (
        <div>
            <StockMarketSwitch/>
            <Route exact strict path={`${match.url}/stock-data`} component={OrganisationStockMarket}/>
            <Route exact path={`${match.url}/product`} component={OrganisationStockProduct}/>
            <Route exact path={`${match.url}`}>
                <Redirect to={`${match.url}/stock-data`}></Redirect>
            </Route>
        </div>

    );
}

export default StockMarketContainer;